import { styled } from 'buttered';
import { ChevronRight } from 'react-feather';
import { Avatar, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { IProject } from '@highloop-pkg/use-ares';

let Wrapper = styled('div')`
  border: solid #efefef 1px;
  border-radius: 12px;
  padding: 18px 20px;
  transition: all 0.3s;
  outline: none;
  background: transparent;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  .arrow {
    opacity: 0.6;
    display: flex;
    height: 16px;
    transition: all 0.3s;
    transform: translateX(-2px);
    color: #444;

    .choose-text {
      height: 16px;
      line-height: 16px;
      opacity: 0;
      transition: all 0.3s;
      font-size: 14px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &:hover,
  &:focus {
    background: #fafafa;

    .arrow {
      opacity: 1;
      transform: translateX(2px);

      .choose-text {
        opacity: 1;
        transform: translateX(-10px);
      }
    }
  }

  @media screen and (max-width: 500px) {
    .choose-text {
      display: none;
    }
  }
`;

let Inner = styled('div')`
  display: flex;
`;

let Details = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export let Chooser = ({ project }: { project: IProject }) => {
  return (
    <Wrapper>
      <Inner>
        <div>
          <Avatar name={project.name} src={project.photoUrl} size={40} />
        </div>

        <div>
          <VerticalCenter>
            <Details>
              <Text as="h1" size={18} weight={500}>
                {project.name}
              </Text>

              <Text as="small" size={12} color="#888">
                {project.slug}.highloop.work
              </Text>
            </Details>
          </VerticalCenter>
        </div>

        <Spacer />

        <div>
          <VerticalCenter>
            <div className="arrow">
              <div className="choose-text">Choose</div>

              <ChevronRight />
            </div>
          </VerticalCenter>
        </div>
      </Inner>
    </Wrapper>
  );
};
