import {
  Avatar,
  Button,
  Center,
  Entity,
  Notice,
  Spacer,
  Spinner,
  Text,
  VerticalCenter
} from '@vapor/ui';
import { styled } from 'buttered';
import React, { useMemo, useEffect, useState } from 'react';
import Link from 'next/link';
import { Layout } from '../layout';
import { ProjectSelector } from '../components/compound/project';
import { useProjects, useUser } from '../state/useAres';
import { useQuery } from '../hooks/useQuery';
import { Chooser } from '../components/compound/chooser';
import { config } from '../../config';
import { Calendars, LoadingCalendars } from '../components/calendar';
import { useWeekrise } from '../state/calendar';

let Weekrise = styled('div')`
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  background-color: #ead6ff;
  background-image: linear-gradient(74deg, #ead6ff 0%, #ffd3f2 100%);
  --vapor-primary: #faaca8;
  img {
    width: 60px;
  }
  main {
    margin-left: 20px;
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      opacity: 0.6;
    }
  }
  @media screen and (max-width: 600px) {
    display: block;
    img {
      width: 40px;
    }
    main {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;

let Header = styled('header')`
  margin-bottom: 20px;
`;

let Wrapper = styled('header')`
  max-width: 1100px;
  margin: 150px auto 20px auto;
  padding: 0px 15px;

  @media screen and (max-width: 900px) {
    margin: 40px auto 20px auto;
  }
`;

let Grid = styled('div')`
  display: grid;
  grid-template-columns: calc(100% - 350px) 300px;
  gap: 50px;

  @media screen and (max-width: 900px) {
    gap: 0px;
    grid-template-columns: 100%;
  }
`;

let Sidebar = styled('aside')`
  text-align: center;

  .center {
    margin: 0px auto;
    width: fit-content;
  }

  h1 {
    font-size: 26px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

let Holiday = styled('div')`
  height: 40px;
  background: var(--vapor-green);
  color: white;
  border-radius: 10px;
  padding: 0px 18px;
  line-height: 40px;
  width: fit-content;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
`;

export default function Home() {
  let { projects, loading, error } = useProjects();
  let { user } = useUser();
  let {
    enabled: weekriseEnabled,
    calendars,
    tasks,
    rolledOver,
    loading: weekriseLoading
  } = useWeekrise();
  let [holidays, setHolidays] = useState([]);
  useEffect(() => (window as any).getCurrentHolidays().then(setHolidays), []);

  let next = useQuery('next');
  let product = useQuery('product');

  useEffect(() => {
    if (product == 'weekrise') {
      if (next && next.includes('app.weekrise.com')) {
        window.location.replace(next);
      } else {
        window.location.replace('https://app.weekrise.com');
      }
    }
  }, [product, next]);

  let path = useMemo(() => {
    let nextUrl = new URL(next || '', 'https://onhighloop.com');

    let path = nextUrl.pathname;
    if (path.endsWith('/')) path = path.slice(0, -1);

    return path;
  }, [next, product]);

  return (
    <Layout>
      <Wrapper>
        {error && (
          <>
            <Notice variant="error">Could not load projects. Please try again later.</Notice>
            <Spacer height="20px" />
          </>
        )}

        {loading || !Array.isArray(projects) ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Grid>
            <main>
              <Header style={!user ? { opacity: 0 } : {}}>
                {holidays.length > 0 && (
                  <Holiday>
                    <p>{holidays[0].name}</p>
                  </Holiday>
                )}

                <Text size={30} weight={700} as="h1">
                  Hey, {user?.name}!
                </Text>

                <Spacer height="5px" />

                <Text size={20} weight={600} as="h2" style={{ opacity: 0.5 }}>
                  Choose a project to continue.
                </Text>
              </Header>

              {(!product || product == 'weekrise') && (
                <>
                  {weekriseLoading ? (
                    <LoadingCalendars />
                  ) : weekriseEnabled ? (
                    <Calendars calendars={calendars} tasks={tasks} rolledOver={rolledOver} />
                  ) : (
                    <Weekrise>
                      <div>
                        <VerticalCenter>
                          <img src="/weekrise-logo.svg" alt="Weekrise" />
                        </VerticalCenter>
                      </div>

                      <div>
                        <VerticalCenter>
                          <main>
                            <h1>Open your Weekrise Calendar</h1>
                            <p>Weekrise is an effective weekly task planner.</p>

                            <Spacer height="15px" />

                            <a href="https://app.weekrise.com" style={{ display: 'flex' }}>
                              <Button as="div" variant="primaryAlt" size="small">
                                Open Calendar
                              </Button>
                            </a>
                          </main>
                        </VerticalCenter>
                      </div>
                    </Weekrise>
                  )}
                </>
              )}

              {projects.map(p => {
                if (product == 'feedback' || product == 'launch' || product == 'releases') {
                  return (
                    <Link href={`https://${product}.highloop.co/${p.slug}${path}`} key={p.id}>
                      <a>
                        <Chooser project={p} />
                      </a>
                    </Link>
                  );
                }

                return (
                  <ProjectSelector
                    key={p.id}
                    project={p}
                    products={[
                      {
                        type: 'feedback',
                        name: 'Feedback',
                        id: 'feedback'
                      },
                      {
                        type: 'launch',
                        name: 'Launch',
                        id: 'launch'
                      },
                      {
                        type: 'releases',
                        name: 'Releases',
                        id: 'releases'
                      }
                    ]}
                  />
                );
              })}

              <Entity.Wrapper>
                <Entity.Content>
                  <Entity.Field title="Create Project" />

                  <Entity.Field title="Actions" right>
                    <Link href={config.projectFrontend + '/setup'}>
                      <a>
                        <Button size="small" variant="primary" as="div">
                          Create Project
                        </Button>
                      </a>
                    </Link>
                  </Entity.Field>
                </Entity.Content>
              </Entity.Wrapper>
            </main>

            <Sidebar>
              <aside>
                <div className="center">
                  <Avatar src={user?.photoUrl} size={150} name={user?.name || 'Loading...'} />
                </div>

                <Spacer height="20px" />

                <div className="center">
                  <h1>{user?.name}</h1>
                </div>

                <Spacer height="5px" />

                <div className="center">
                  <p>{user?.email}</p>
                </div>

                <Spacer height="20px" />

                <div className="center">
                  <a href={config.accountFrontend}>
                    <Button variant="secondary" size="small" as="div">
                      Manage Account
                    </Button>
                  </a>
                </div>
              </aside>
            </Sidebar>
          </Grid>
        )}
      </Wrapper>
    </Layout>
  );
}
