import { styled } from 'buttered';
import { ChevronRight, Settings } from 'react-feather';
import { Avatar, Button, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { IProject } from '@highloop-pkg/use-ares';
import React from 'react';
import Link from 'next/link';
import { config } from '../../../config';

let Wrapper = styled('section')`
  border: solid #efefef 1px;
  border-radius: 12px;
  margin-bottom: 20px;
`;

let Header = styled('header')`
  padding: 18px 20px;
  display: flex;
`;

let Details = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

let Products = styled('div')``;

let ProductInner = styled('div')`
  display: flex;
`;

let Product = styled('div')`
  padding: 13px 20px;
  transition: all 0.3s;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  border-top: solid #efefef 1px;
  text-align: left;

  .arrow {
    opacity: 0.6;
    display: flex;
    height: 16px;
    transition: all 0.3s;
    transform: translateX(-2px);
    color: #444;

    .choose-text {
      height: 16px;
      line-height: 16px;
      opacity: 0;
      transition: all 0.3s;
      font-size: 14px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &:hover,
  &:focus {
    background: #fafafa;

    .arrow {
      opacity: 1;
      transform: translateX(2px);

      .choose-text {
        opacity: 1;
        transform: translateX(-10px);
      }
    }
  }

  @media screen and (max-width: 500px) {
    .choose-text {
      display: none;
    }
  }
`;

export let ProjectSelector = ({
  project,
  products
}: {
  project: IProject;
  products: {
    type: string;
    id: string;
    name: string;
  }[];
}) => {
  return (
    <Wrapper>
      <Header>
        <div>
          <Avatar name={project.name} src={project.photoUrl} size={40} />
        </div>

        <div>
          <VerticalCenter>
            <Details>
              <Text as="h1" size={18} weight={500}>
                {project.name}
              </Text>

              <Spacer height={2} />

              <Text as="small" size={12} color="#888">
                {project.slug}.highloop.work
              </Text>
            </Details>
          </VerticalCenter>
        </div>

        <Spacer />

        <div>
          <VerticalCenter>
            <div>
              <Link href={`${config.projectFrontend}/${project.slug}`}>
                <a>
                  <Button
                    icon={<Settings />}
                    aria-label="Team Settings"
                    size="smaller"
                    as="div"
                  />
                </a>
              </Link>
            </div>
          </VerticalCenter>
        </div>
      </Header>

      <Products>
        {products.map(product => (
          <Link key={product.id} href={`https://${product.id}.highloop.co/${project.slug}`}>
            <a>
              <Product>
                <ProductInner>
                  <div style={{ margin: '0px 5px', padding: '6px', display: 'flex' }}>
                    <Avatar
                      name={product.name}
                      src={`/${product.type}-logo.svg`}
                      radius="5"
                      size={18}
                    />
                  </div>

                  <div>
                    <VerticalCenter>
                      <Details>
                        <Text as="h3" size={16} weight={400}>
                          {product.name}
                        </Text>
                      </Details>
                    </VerticalCenter>
                  </div>

                  <Spacer />

                  <div>
                    <VerticalCenter>
                      <div className="arrow">
                        <div className="choose-text">Choose</div>

                        <ChevronRight />
                      </div>
                    </VerticalCenter>
                  </div>
                </ProductInner>
              </Product>
            </a>
          </Link>
        ))}
      </Products>
    </Wrapper>
  );
};
