import { useRouter } from 'next/router';

export let useQuery = (id: string) => {
  let router = useRouter();

  let query = Array.isArray(router.query[id])
    ? router.query[id][0]
    : (router.query[id] as string);

  if (!query && typeof window != 'undefined') {
    query = new URLSearchParams(window.location.search).get(id);
  }

  return query;
};
